.ant-table-thead .ant-table-cell {
    background-color: #02b1c82f !important;
    color: #000 !important;
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.f-size-12 {
    font-size: 12px;
}

.bold {
    font-weight: bold;
}

.chart-blue {
    color: #02B0C8;
}

.chart-orange {
    color: #FA981B;
}

.chart-rose {
    color: #C72B83;
}

.chart-bg-orange, .chart-bg-rose, .chart-bg-green {
    color: #fff;
}

.chart-bg-green {
    background-color: #27ae60;
}

.chart-bg-orange {
    background-color: #FA981B;
}

.chart-bg-rose {
    background-color: #C72B83;
}

.menu-link:hover{
    background-color: #f86812 !important;
}

#menu-link-new:hover{
    background-color: #f8f8f8 !important;

 }
